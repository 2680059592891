import { Colors } from '@/utils/consts';
import { css, Global, CacheProvider } from '@emotion/react';
import React, { useCallback, useEffect, useState } from 'react';
import createCache from '@emotion/cache';
import stylisPx2RemPlugin from '@/utils/px2remPlugin';
import FontUrl from '@/assets/DIN-Bold.ttf';
import { ResizeContext } from '@/ctx/ResizeContext';

const myCache = createCache({
  key: 'idmesh',
  stylisPlugins: [stylisPx2RemPlugin({
    remSize: 100
  })],
})

const GlobalCss = css`
  body {
    margin: 0;
    font-size: 0.16rem;
    color: ${Colors.textMain};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 2;
  }
  @font-face{
    font-family: 'DIN';
    src : url(${FontUrl});
  }
  a {
    text-decoration: none;
  }
`;

// const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
const isFirefox = true;
const minWidth = 1400;
const maxMobileWidth = 750;

export default function Layout({ children }: React.PropsWithChildren) {
  const [resizeData, setResizeData] = useState({ isMobile: false });

  const onResize = useCallback(() => {
    const screenWidth = window.innerWidth;
    setResizeData({ isMobile: screenWidth <= 750 });
    if (screenWidth >= minWidth) {
      window.scale = 1;
      (document.body.style as any).zoom = '';
      document.documentElement.style.fontSize = '100px';
      return;
    }
    const scale = screenWidth > maxMobileWidth ? screenWidth / minWidth : screenWidth / maxMobileWidth * 2;
    if (isFirefox) {
      window.scale = scale;
      document.documentElement.style.fontSize = `${100 * scale}px`;
    } else {
      (document.body.style as any).zoom = `${scale}`;
    }
  }, []);

  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);
    const mask = document.querySelector('#g-loading');
    mask?.parentNode?.removeChild(mask);
    return () => window.removeEventListener('resize', onResize);
  }, []);
  

  return (
    <ResizeContext.Provider value={resizeData}>
      <Global
        styles={GlobalCss}
      />
      <CacheProvider value={myCache}>
        { children }
      </CacheProvider>
    </ResizeContext.Provider>
  );
}
