export const Colors = {
  textMain: '#171618',
  textSecondary: '#5B5A59',
  primaryBtn: '#1E60DD',
  primaryBtnActive: '#476fd9',
  borderBtnBgActive: '#e4e9f5',
};

export const Info = {
  tel: '400-928-1656',
  telSH: '021-61630550',
  salesEmail: 'sales@sensesw.com',
  adminEmail: 'idmesh@sensesw.com',
  githubLink: 'https://github.com/sense-soft',
  docLink: 'https://thoughts.teambition.com/sharespace/63c78de779054e00410dff8c',
  authApiDocLink: 'https://apidoc.idmesh.cn/',
};

export const Style = {
  defaultTransition: 'all .2s cubic-bezier(.645,.045,.355,1)',
  transition: (key: string) => `${key} .2s cubic-bezier(.645,.045,.355,1)`,
};

export const px2rem = (x: number) => `${(x / 100)}rem`;

export const cls = (...arr: any[]) => arr.filter(Boolean).join(' ');

export const sleep = (t: number) => new Promise(res => setTimeout(res, t));
